import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPlay } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark;
    const { previous, next, first, last } = this.props.pageContext;
    const prevClassNames = classnames({"text-dark": previous && !previous.frontmatter.draft && !!previous.fields.slug, "text-muted": !previous || previous && previous.frontmatter && previous.frontmatter.draft }, "h2 text-decoration-none")
    const nextClassNames = classnames({"text-dark": next && !next.frontmatter.draft && !!next.fields.slug, "text-muted": !next || next && next.frontmatter && next.frontmatter.draft }, "h2 text-decoration-none")
    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          image={post && post.frontmatter.featuredImage && post.frontmatter.featuredImage.publicURL}
          slug={post.fields.slug}
        />
        <div className="article">
          <article dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <div className="post-footer w-100 px-5 py-3 bg-white" style={{position:"sticky", bottom:0}}>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              listStyle: 'none',
              padding: 0,
            }}
          >
              <li className="pr-4">
                <Link disabled={!previous || previous && previous.frontmatter.draft} className={prevClassNames} to={previous && !previous.frontmatter.draft && previous.fields.slug} rel="prev">
                  <FontAwesomeIcon rotation={180} icon={faPlay} />
                </Link>
              </li>
              <li className="pl-4">
                  <Link disabled={!next || next && next.frontmatter.draft} className={nextClassNames} to={next && !next.frontmatter.draft && next.fields.slug} rel="next">
                    <FontAwesomeIcon icon={faPlay} />
                  </Link>
              </li>

          </ul>
        </div>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
        path
        tags
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
